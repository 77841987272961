const gs1us = require('./gs1us-didvc-web.js');
const presentation = require('./novartis_vp.json');
const presentation_revoked = require('./test-vp-revoked.json');
const presentation_invalidParty = require('./test-vp-party_invalid.json');

export const defaultClaims = {
    showStatus: false,
    verificationStatus: false,
    issuer: "",
    issuedDate: "",
    subjectdid: "",
    origanization: "",
    rootOfTrustDid: "",
    rootOfTrustOrganization: "",
    partyGln: "",
    errors: []
   }

export async function verifier(credentailId) {

    const configOptions = {
        internalRevocationCheck: false,
        enableLogging: false,
        enableCaching: true,
        verificationOutput: 'basic',
        rootOftrustUrl: 'did:web:ns-dev.gs1.org' // New Required Parameter 
    }

    let credentailInput = null;

    switch (credentailId) {
        case "1":
            credentailInput = presentation;
            break;
        case "2":
            credentailInput = presentation_revoked;
            break;
        case "3":
            credentailInput = presentation_invalidParty;
            break;
        default:
            credentailInput = presentation;
            break;
    }

    // Verified the samples. 
   const verifyResult = await gs1us.verifier.verifyPresentation(configOptions, credentailInput);

    console.log("_______________________________");
    console.log("testVeriferExternalResolver - Verify Result");
    console.log("_______________________________");
    console.log(JSON.stringify(verifyResult, null, 2));

    const claims = verifyResult.validationClaims;
    console.log(claims);

    const credentialOutputTest = {
        showStatus: true,
        validated: claims.validated,
        issuer: claims.issuer,
        issuedDate: new Date(claims.issuedDate).toLocaleDateString(),
        organizationDid: claims.organizationDid,
        organizationName: claims.organizationName,
        rootOfTrustDid: claims.rootOfTrustDID,
        rootOfTrustOrganization: claims.rootOfTrustOrganization,
        partyGln: claims.subjectFields.partyGLN,
        errors: verifyResult.errors.length == 0 ? ["No Errors"] : verifyResult.errors.map(error => {return error.rule;})
       }

       return credentialOutputTest;
}
