<div class="main_container">
  <div class="headerBackground">
    <img src="../assets/SaeLogo.PNG"/>
  </div>
  <div class="formHeader">
    <span class="formHeader_title">Prospective Supplier Form</span>
  </div>
  
  <div class="main" >
    <h2>Supplier Registration Progress</h2>
    <mat-progress-bar mode="determinate" value="40" class="progress_bar"></mat-progress-bar>
    <div class="description">
      Please provide your GS1 Licence Credentials using your Digital Wallet
    </div>
    <div class="verifier-status_margin ">
      <app-verifier-status></app-verifier-status>
    </div>
  </div>
  
</div>


