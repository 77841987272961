import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { VerifierStatusComponent } from './verifier-status/verifier-status.component';

import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';

import { NgxConfigureModule, NgxConfigureOptions } from 'ngx-configure';
import { AppOptions } from 'src/services/app-options';

@NgModule({
  declarations: [
    AppComponent,
    VerifierStatusComponent
  ],
  imports: [
    BrowserModule,
    NgxConfigureModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MatProgressBarModule,
    MatSliderModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatGridListModule,
    MatExpansionModule,
    MatTableModule
  ],
  providers: [
    { provide: NgxConfigureOptions, useClass: AppOptions }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
