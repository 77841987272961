import { Component } from '@angular/core';
import { ChapiService } from 'src/services/chapi-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sae-verifier';

  constructor(private chapiService: ChapiService) {
    chapiService.initChapi();
  }
}
