import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxConfigureService } from 'ngx-configure';
import { Injectable } from '@angular/core';
import { CredentialStatus, CredentialStatusResult, CredentialStatusVerified, GetEmptyStatus } from 'src/model/credential-status';
import { ServerCredential, ServerStatus } from 'src/model/server-status';

@Injectable({ providedIn: 'root' })
export class VerifierService {

    private REST_API_SERVER: string = "";
    // private POST_URL = this.REST_API_SERVER + "/api/verifer";

    private config: any;
    
    constructor(private configService: NgxConfigureService, private httpClient: HttpClient) {
        this.config = configService.config;
        this.REST_API_SERVER = this.config.useServer ? this.config.credentialServerUri : this.config.credentialServerUri_local;
    }

    verifyCredential(credential: any) {

        const POST_URL = this.REST_API_SERVER + "/api/verifer";
        const headers = new HttpHeaders().set("Content-Type", "application/json");
        return this.httpClient.post(POST_URL, credential, {headers} );
    }

    getCredential(credentialId: string, serverData: ServerStatus): ServerCredential {
      return serverData.results[0].verifiableCredential.find( (q: any) => {return q.id === credentialId}); 
    }

    // Todo: Better Handle Looking up credential type
    getCredentialType(verifiableCredential: any) {
        return verifiableCredential.type.length > 1 ? verifiableCredential.type[1]: verifiableCredential.type[0];
    }

    getVerifierStatus(serverData: ServerStatus): CredentialStatusResult {
        let statusResult: CredentialStatusResult = new CredentialStatusResult();
        statusResult.verificationStatusMessage = "Credential verification succesfully";

        statusResult.companyPrefixCredential = GetEmptyStatus("companyPrefixCredential");
        statusResult.keyCredential = GetEmptyStatus("keyCredential");
        statusResult.partyCredential = GetEmptyStatus("partyCredential");
 
        serverData.credentialResults.forEach(item => {
            const sourceCredential = this.getCredential(item.credentialId, serverData);

            const itemStatus = GetEmptyStatus("");
            itemStatus.credentialId = item.credentialId;
            itemStatus.issuer = sourceCredential.issuer.id;
            itemStatus.type = this.getCredentialType(sourceCredential);

            itemStatus.fields = [];

            Object.entries(sourceCredential.credentialSubject).forEach(
                ([key, value]) => {
                    if ( Object.prototype.toString.call(value) === "[object Object]") {
                        const subKey = key;
                        Object.entries(value).forEach(
                            ([key, value]) => itemStatus.fields.push({name: subKey + " " + key, value: value})
                        );
                    } else {
                        itemStatus.fields.push({name: key, value: value});
                    }
                }
              );

            itemStatus.verified = item.verified ? CredentialStatusVerified.verificationSuccess : CredentialStatusVerified.verificationFailed;
            itemStatus.errors = [];
            if (item.error) {
                item.error.errors.forEach(error => {
                    itemStatus.errors.push({
                        name: error.name,
                        message: error.message
                    })
                });
            }
            if (item.statusResult) {
                if (item.statusResult.errors) {
                    item.statusResult.errors.forEach(error => {
                        itemStatus.errors.push({
                            name: "Status error",
                            message: error
                        })
                    });
                }
     
            }

            switch(itemStatus.type) {
                case "GS1LicenseCredential":
                    statusResult.licenseCredential = itemStatus;
                    break;
                case "GS1CompanyPrefixLicenceCredential":
                    statusResult.companyPrefixCredential = itemStatus;
                    break;
                case "GS1KeyCredential":
                    statusResult.keyCredential = itemStatus;
                    break;
                case "GS1PartyDataCredential":
                    statusResult.partyCredential = itemStatus;
                    break;
            }
        });

        // Setup GS1 License Credential (Not Returned from the Server)
        statusResult.licenseCredential = new CredentialStatus();
        statusResult.licenseCredential.type = "GS1LicenseCredential";
        statusResult.licenseCredential.issuer = statusResult.companyPrefixCredential.verified ? "did:web:ns-dev.gs1.org" : "",
        statusResult.licenseCredential.verified = statusResult.companyPrefixCredential ? statusResult.companyPrefixCredential.verified : CredentialStatusVerified.noResult;
        statusResult.licenseCredential.errors = [];

        // Overall Verification Result
        statusResult.verificationResult = serverData.verified;
        statusResult.verificationStatusMessage = serverData.verified ? "Credential verification succesfully": "Verification failed! See individual credential below for more details.";

        return statusResult;
   
     }

}