import { Injectable } from '@angular/core';

// @ts-ignore
import * as polyfill from 'credential-handler-polyfill';

// @ts-ignore
import {CreateWebCredential, GetCredential} from './create-web-credential.js';

@Injectable({ providedIn: 'root' })
export class ChapiService {

    // http://slavik.meltser.info/the-efficient-way-to-create-guid-uuid-in-javascript-with-explanation/  
    createGuid() {  
    function _p8(s: boolean) {  
        var p = (Math.random().toString(16)+"000000000").substr(2,8);  
        return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;  
    }  
    return _p8(false) + _p8(true) + _p8(true) + _p8(false);  
    }  
  
    async initChapi() {
        const MEDIATOR = 'https://authn.io/mediator' + '?origin=' +
        encodeURIComponent(window.location.origin);

        await polyfill.loadOnce(MEDIATOR)
        .then(console.log('Polyfill loaded.'))
        .catch((e: any) => console.error('Error loading polyfill:', e));
        
        console.log('Ready to work with credentials!');
    }
      
  async didAuthRequest() {

    const challenge =  this.createGuid(); 

    const request: any = {
      // we want a "web" credential via CHAPI, not a "password" credential
      // via the browser's password manager
      web: {
        // the type of "web" credential is a "VerifiablePresentation"
        VerifiablePresentation: {
          // *now* here is where the VPR spec comes in with the
          // example you were trying to use... so all the other examples
          // should be nested in here as well
          query: {
            type: 'DIDAuth'
          },
          challenge: challenge,
          domain: 'sa-e.net'
        }
      }
    };
    const result = await navigator.credentials.get(request);
    console.log(result);
    
  }

  async AddCredentialToWallet(credential: any) {
   
    const credentialType = 'VerifiablePresentation';
    const webCredentialWrapper = await CreateWebCredential(credentialType, credential);

    // // Use Credential Handler API to store
    const result = await navigator.credentials.store(webCredentialWrapper);

    console.log('Result of receiving via store() request:', result);
  }
  
  async getCredentialFromWallet() {

    const challenge =  this.createGuid(); 

    const credentialQueryGS1US =  {
      "web": {
        "VerifiablePresentation": {
          "challenge": challenge,
          "domain": "sa-e.net",
          "query": [
            {
              "type": "QueryByExample",
              "credentialQuery": {
                "reason": "Please present a GS1 Credential for Happy Tots.",
                "example": {
                  "@context": [
                    "https://w3id.org/credentials/v1",
                    "https://ns-dev.gs1.org/vc/licence/licence.jsonld"
                  ],
                  "type": [
                    "GS1PartyDataCredential",
                    "GS1KeyCredential",
                    "GS1CompanyPrefixLicenceCredential"
                  ],
                  "credentialSubject": {
                    "id": "did:example:ebfeb1f712ebc6f1c276e12ec21"
                  }
                }
              }
            }
          ]
        },
        "recommendedHandlerOrigins": [
          "https://localhost:4200"
        ]
      }
    }

    const result = await GetCredential(credentialQueryGS1US);
    return result;
  }


}