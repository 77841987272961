export const mockServerResult = {
    "verified": false,
    "results": [
        {
        "@context": [
            "https://www.w3.org/2018/credentials/v1"
        ],
        "type": [
            "VerifiablePresentation"
        ],
        "verifiableCredential": [
            {
            "@context": [
                "https://www.w3.org/2018/credentials/v1",
                "https://ns-dev.gs1.org/vc/licence/licence-context.jsonld",
                "https://ns-dev.gs1.org/vc/licence/declaration-context.jsonld",
                "https://w3id.org/security/suites/ed25519-2020/v1",
                {
                "name": "https://schema.org/name",
                "description": "https://schema.org/description",
                "image": "https://schema.org/image"
                },
                "https://w3id.org/vc-revocation-list-2020/v1"
            ],
            "issuer": {
                "id": "did:web:bornidentity.net",
                "image": "",
                "name": "GS1"
            },
            "name": "GS1 Key Credential",
            "description": "GS1KeyCredential",
            "issuanceDate": "2021-05-11T10:50:36.701Z",
            "id": "did:example:60cda318-a0a7-4e39-b600-ea38bf68a31f",
            "type": [
                "VerifiableCredential",
                "GS1KeyCredential"
            ],
            "credentialSubject": {
                "id": "https://id.gs1.org/417/08600057694000005",
                "extendsCredential": "http://did-vc.gs1us.org/vc/licence/08600057694"
            },
            "credentialStatus": {
                "id": "https://ns-dev.gs1.org/vc/licence/status/gs1_prefix/08",
                "type": "CredentialStatusList2017"
            },
            "proof": {
                "type": "Ed25519Signature2020",
                "created": "2021-05-27T19:42:15Z",
                "verificationMethod": "did:web:healthytots.net#z6MkivkVCosEsJM3VQq2JcqbM8a9EzKpdY6jgRx6jWUdkGYx",
                "proofPurpose": "assertionMethod",
                "proofValue": "z2NoW1kXrefNCPzQ5GuDUPdoYggQdyz2fFtzKuQ9tevJZ3QiMBj4drUzJRMLjdvJboBB34iWRVeYL7Kx7ZmVJra6B"
            }
            },
            {
            "@context": [
                "https://www.w3.org/2018/credentials/v1",
                "https://ns-dev.gs1.org/vc/licence/licence-context.jsonld",
                "https://ns-dev.gs1.org/vc/licence/declaration-context.jsonld",
                "https://w3id.org/security/suites/ed25519-2020/v1",
                {
                "name": "https://schema.org/name",
                "description": "https://schema.org/description",
                "image": "https://schema.org/image"
                },
                "https://w3id.org/vc-revocation-list-2020/v1"
            ],
            "issuer": {
                "id": "did:web:bornidentity.net",
                "image": "",
                "name": "GS1"
            },
            "name": "GS1 Party Identification Credential",
            "description": "GS1PartyIdentificationCredential",
            "issuanceDate": "2021-05-11T10:50:36.701Z",
            "id": "did:example:8ab6ecf2-cf08-4136-a853-39f7ad6fb94f",
            "type": [
                "VerifiableCredential",
                "GS1PartyIdentificationCredential"
            ],
            "credentialSubject": {
                "id": "did:web:www.healthytots.com",
                "keyCredential": "did:example:60cda318-a0a7-4e39-b600-ea38bf68a31f",
                "partyGLN": "08600057694000005",
                "organizationName": {
                "language": "en",
                "value": "Example Company"
                }
            },
            "credentialStatus": {
                "id": "https://ns-dev.gs1.org/vc/licence/status/gs1_prefix/08test123",
                "type": "CredentialStatusList2017"
            },
            "proof": {
                "type": "Ed25519Signature2020",
                "created": "2021-05-27T19:41:32Z",
                "verificationMethod": "did:web:bornidentity.net#z6Mkm7s2iUPMD8FJD8w3K7WP4qtAchctVJraXSYNM7gTPwxy",
                "proofPurpose": "assertionMethod",
                "proofValue": "z415Dd9T9pKRAYgg79c9SU3BvcBu6ugSFSFknacqC9H6GqFF4DRot4tmP6NcgHcgXmDfD7uvLG2s69TbQWaCX7zVv"
            }
            },
            {
            "@context": [
                "https://www.w3.org/2018/credentials/v1",
                "https://ns-dev.gs1.org/vc/licence/licence-context.jsonld",
                "https://w3id.org/security/suites/ed25519-2020/v1",
                {
                "name": "https://schema.org/name",
                "description": "https://schema.org/description",
                "image": "https://schema.org/image"
                },
                "https://w3id.org/vc-revocation-list-2020/v1"
            ],
            "issuer": {
                "id": "did:web:did-vc.gs1us.org",
                "image": "",
                "name": "GS1"
            },
            "name": "GS1 Company Prefix Licence",
            "description": "A company prefix that complies with GS1 Standards (a “GS1 Company Prefix”) is a unique identification number that is assigned to just your company by GS1 US. It’s the foundation of GS1 Standards and can be found in all of the GS1 Identification Numbers.",
            "issuanceDate": "2021-05-11T10:50:36.701Z",
            "id": "http://did-vc.gs1us.org/vc/licence/08600057694",
            "type": [
                "VerifiableCredential",
                "GS1CompanyPrefixLicenceCredential"
            ],
            "credentialSubject": {
                "id": "did:web:healthytots.net",
                "organizationName": {
                "language": "en",
                "value": "Healthy Tots"
                },
                "extendsCredential": "https://ns-dev.gs1.org/vc/licence/gs1_prefix/08",
                "licenceValue": "08600057694",
                "alternativeLicenceValue": "8600057694"
            },
            "credentialStatus": {
                "id": "https://ns-dev.gs1.org/vc/licence/status/gs1_prefix/08",
                "type": "CredentialStatusList2017"
            },
            "proof": {
                "type": "Ed25519Signature2020",
                "created": "2021-05-27T19:42:37Z",
                "verificationMethod": "did:web:did-vc.gs1us.org#z6MkkzpGdk7XAWGJa8YJeDxtQhNYWgRuoMnuZbrnwTNd3p5r",
                "proofPurpose": "assertionMethod",
                "proofValue": "z34jQQqbG51GohzxUjKrciQc5BU7tqRC56xg2KkAG1SZfakPupDvfrLtYwiy44ummkhTnnAv8HVEboyhMjiMLe4g7"
            }
            }
        ],
        "id": "did:key:z6Mkn2PzSSh7qJpiPw4AsDrMN2WYkd69qrABZdjN3K7XwFFi/01",
        "holder": "did:key:z6Mkn2PzSSh7qJpiPw4AsDrMN2WYkd69qrABZdjN3K7XwFFi"
        }
    ],
    "credentialResults": [
        {
        "verified": false,
        "results": [
            {
            "proof": {
                "@context": [
                "https://www.w3.org/2018/credentials/v1",
                "https://ns-dev.gs1.org/vc/licence/licence-context.jsonld",
                "https://ns-dev.gs1.org/vc/licence/declaration-context.jsonld",
                "https://w3id.org/security/suites/ed25519-2020/v1",
                {
                    "name": "https://schema.org/name",
                    "description": "https://schema.org/description",
                    "image": "https://schema.org/image"
                },
                "https://w3id.org/vc-revocation-list-2020/v1"
                ],
                "type": "Ed25519Signature2020",
                "created": "2021-05-27T19:42:15Z",
                "verificationMethod": "did:web:healthytots.net#z6MkivkVCosEsJM3VQq2JcqbM8a9EzKpdY6jgRx6jWUdkGYx",
                "proofPurpose": "assertionMethod",
                "proofValue": "z2NoW1kXrefNCPzQ5GuDUPdoYggQdyz2fFtzKuQ9tevJZ3QiMBj4drUzJRMLjdvJboBB34iWRVeYL7Kx7ZmVJra6B"
            },
            "verified": false,
            "error": {
                "name": "Error",
                "message": "Invalid signature.",
                "stack": "Error: Invalid signature.\n    at Ed25519Signature2020.verifyProof (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\jsonld-signatures\\lib\\suites\\LinkedDataSignature.js:186:15)\n    at processTicksAndRejections (internal/process/task_queues.js:93:5)\n    at async Promise.all (index 0)\n    at async _verify (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\jsonld-signatures\\lib\\ProofSet.js:210:11)\n    at async ProofSet.verify (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\jsonld-signatures\\lib\\ProofSet.js:148:23)\n    at async Object.verify (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\jsonld-signatures\\lib\\jsonld-signatures.js:114:18)\n    at async _verifyCredential (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\@digitalbazaar\\vc\\lib\\vc.js:271:18)\n    at async Promise.all (index 0)\n    at async _verifyPresentation (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\@digitalbazaar\\vc\\lib\\vc.js:408:25)\n    at async Object.verifyUnsignedPresentation (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\src\\verifier\\verify.js:37:20)"
            }
            }
        ],
        "error": {
            "name": "VerificationError",
            "errors": [
            {
                "name": "Error",
                "message": "Invalid signature.",
                "stack": "Error: Invalid signature.\n    at Ed25519Signature2020.verifyProof (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\jsonld-signatures\\lib\\suites\\LinkedDataSignature.js:186:15)\n    at processTicksAndRejections (internal/process/task_queues.js:93:5)\n    at async Promise.all (index 0)\n    at async _verify (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\jsonld-signatures\\lib\\ProofSet.js:210:11)\n    at async ProofSet.verify (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\jsonld-signatures\\lib\\ProofSet.js:148:23)\n    at async Object.verify (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\jsonld-signatures\\lib\\jsonld-signatures.js:114:18)\n    at async _verifyCredential (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\@digitalbazaar\\vc\\lib\\vc.js:271:18)\n    at async Promise.all (index 0)\n    at async _verifyPresentation (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\node_modules\\@digitalbazaar\\vc\\lib\\vc.js:408:25)\n    at async Object.verifyUnsignedPresentation (C:\\Users\\toddf\\source\\repos\\VerifiableCredentialsandDIDs\\verifiable-credentials\\server\\credential-server\\src\\verifier\\verify.js:37:20)"
            }
            ]
        },
        "credentialId": "did:example:60cda318-a0a7-4e39-b600-ea38bf68a31f"
        },
        {
        "verified": false,
        "results": [
            {
            "proof": {
                "@context": [
                "https://www.w3.org/2018/credentials/v1",
                "https://ns-dev.gs1.org/vc/licence/licence-context.jsonld",
                "https://ns-dev.gs1.org/vc/licence/declaration-context.jsonld",
                "https://w3id.org/security/suites/ed25519-2020/v1",
                {
                    "name": "https://schema.org/name",
                    "description": "https://schema.org/description",
                    "image": "https://schema.org/image"
                },
                "https://w3id.org/vc-revocation-list-2020/v1"
                ],
                "type": "Ed25519Signature2020",
                "created": "2021-05-27T19:41:32Z",
                "verificationMethod": "did:web:bornidentity.net#z6Mkm7s2iUPMD8FJD8w3K7WP4qtAchctVJraXSYNM7gTPwxy",
                "proofPurpose": "assertionMethod",
                "proofValue": "z415Dd9T9pKRAYgg79c9SU3BvcBu6ugSFSFknacqC9H6GqFF4DRot4tmP6NcgHcgXmDfD7uvLG2s69TbQWaCX7zVv"
            },
            "verified": true,
            "purposeResult": {
                "valid": true
            }
            }
        ],
        "statusResult": {
            "verified": false,
            "errors": [
            "PartyGLN credential has incorrect issuer."
            ]
        },
        "credentialId": "did:example:8ab6ecf2-cf08-4136-a853-39f7ad6fb94f"
        },
        {
        "verified": true,
        "results": [
            {
            "proof": {
                "@context": [
                "https://www.w3.org/2018/credentials/v1",
                "https://ns-dev.gs1.org/vc/licence/licence-context.jsonld",
                "https://w3id.org/security/suites/ed25519-2020/v1",
                {
                    "name": "https://schema.org/name",
                    "description": "https://schema.org/description",
                    "image": "https://schema.org/image"
                },
                "https://w3id.org/vc-revocation-list-2020/v1"
                ],
                "type": "Ed25519Signature2020",
                "created": "2021-05-27T19:42:37Z",
                "verificationMethod": "did:web:did-vc.gs1us.org#z6MkkzpGdk7XAWGJa8YJeDxtQhNYWgRuoMnuZbrnwTNd3p5r",
                "proofPurpose": "assertionMethod",
                "proofValue": "z34jQQqbG51GohzxUjKrciQc5BU7tqRC56xg2KkAG1SZfakPupDvfrLtYwiy44ummkhTnnAv8HVEboyhMjiMLe4g7"
            },
            "verified": true,
            "purposeResult": {
                "valid": true
            }
            }
        ],
        "statusResult": {
            "verified": true
        },
        "credentialId": "http://did-vc.gs1us.org/vc/licence/08600057694"
        }
    ]
}
