import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CredentialOutput, CredentialStatus, CredentialStatusResult, CredentialStatusVerified, GetEmptyStatus } from 'src/model/credential-status';
import { mockServerResult } from 'src/model/mock-server';
import { ServerStatus } from 'src/model/server-status';
import { ChapiService } from 'src/services/chapi-service';
import { VerifierService } from 'src/services/verifier-service';

// @ts-ignore
import {verifier, defaultClaims} from 'src/services/credential/verifier.js';

@Component({
  selector: 'app-verifier-status',
  templateUrl: './verifier-status.component.html',
  styleUrls: ['./verifier-status.component.css']
})
export class VerifierStatusComponent implements OnInit {

  public licenseCredential: CredentialStatus = new CredentialStatus();
  public companyPrefixCredential: CredentialStatus = new CredentialStatus();
  public keyCredential: CredentialStatus = new CredentialStatus();
  public partyIdentificationCredential!: CredentialStatus;
  public verificationStatusMessage: string = "Click to select credentials to verify"
  public verificationResult: boolean = true;
  

  displayedColumns: string[] = ['name', 'value'];

  @ViewChildren(MatExpansionPanel) panels!: QueryList<MatExpansionPanel>;

  constructor(private chapiService: ChapiService, private verifierService: VerifierService) { 
    this.clearCredentials();
  }

  ngOnInit(): void {}

  // Local Test method that use mock data
  async getCredential_m() {
    this.clearCredentials();
    const serverData: ServerStatus = mockServerResult;
    const statusResult = this.verifierService.getVerifierStatus(serverData);
    this.syncDataToUI(statusResult);
   }

   public credentialOutput: CredentialOutput = defaultClaims;

     // Get credentials from wallet and call server to verifer them
  async getCredentialWallet() {

    this.clearCredentials();

    //Close All Panels
    if (this.panels)
      this.panels.forEach(q => q.close());

    const credential = await this.chapiService.getCredentialFromWallet();

    this.verifierService.verifyCredential(credential).subscribe((serverData: any) => {
      const statusResult = this.verifierService.getVerifierStatus(serverData);
      this.syncDataToUI(statusResult);
    })
  }

  // Get test credentials Success
  async getCredential_sucess() {
    this.credentialOutput = defaultClaims;
    const veriferResult = await verifier("1");
    this.credentialOutput = veriferResult;
  }

    // Get test credentials Revoked
async getCredential_revoked() {
  this.credentialOutput = defaultClaims;
  const veriferResult = await verifier("2");
  this.credentialOutput = veriferResult;
}

// Get test credentials Non GS1 Issued
async getCredential_invalidParty() {
  this.credentialOutput = defaultClaims;
  const veriferResult = await verifier("3");
  this.credentialOutput = veriferResult;
}

  
  syncDataToUI(statusResult: CredentialStatusResult) {

    this.verificationResult = statusResult.verificationResult;
    this.verificationStatusMessage = statusResult.verificationStatusMessage;

    if (statusResult.licenseCredential)
      this.licenseCredential = statusResult.licenseCredential;

    if (statusResult.companyPrefixCredential)
      this.companyPrefixCredential = statusResult.companyPrefixCredential;

    if (statusResult.keyCredential)
      this.keyCredential = statusResult.keyCredential;

    if (statusResult.partyCredential)
      this.partyIdentificationCredential = statusResult.partyCredential;
   }

  clearCredentials() {

    // Close All Panels
    if (this.panels)
      this.panels.forEach(q => q.open());

    this.licenseCredential = GetEmptyStatus("licenseCredential");
    this.companyPrefixCredential = GetEmptyStatus("companyPrefixCredential");
    this.keyCredential = GetEmptyStatus("keyCredential");
    this.partyIdentificationCredential = GetEmptyStatus("partyCredential");
  }

}
