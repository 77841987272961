export class CredentialStatusResult {
    verificationResult!: boolean;
    verificationStatusMessage!: string;
    licenseCredential!: CredentialStatus;
    companyPrefixCredential!: CredentialStatus;
    keyCredential!: CredentialStatus;
    partyCredential!: CredentialStatus;
}

export class CredentialStatus {
    type!: string;
    credentialId!: string;
    issuer!: string;
    verified!: CredentialStatusVerified;
    errors!: CredentialError[];
    fields!: CredentialField[];
}

export function GetEmptyStatus(credentialType: string): CredentialStatus {
    return {
        type: credentialType,
        credentialId: "",
        issuer: "",
        verified: CredentialStatusVerified.noResult,
        errors: [],
        fields: []
    }
}

export enum CredentialStatusVerified {
    noResult = 0,
    verificationSuccess = 1,
    verificationFailed = 2
}

export class CredentialField {
    name!: string;
    value!: any;
}

export class CredentialError {
    name!: string;
    message!: string;
}

export class CredentialOutput {
    validated!: boolean;
    showStatus!: boolean;
    issuer!: string;
    issuedDate!: string;
    organizationDid!: string;
    organizationName!: string;
    rootOfTrustDid!: string;
    rootOfTrustOrganization!: string;
    partyGln!: string;
    errors!: string[]

}