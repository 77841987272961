<div class="container">
    <div class="container">
        <div class="row">
            <div class="col-2">
                <button mat-raised-button color="primary" (click)="getCredential_sucess()">Present Credentials</button>
             </div>
            <div class="col-2">
                <button mat-raised-button color="primary" (click)="getCredential_revoked()">Revoked Credentials</button>

            </div>
            <div class="col-2">
                <button mat-raised-button color="primary" (click)="getCredential_invalidParty()">Invalid Party GLN Credentials</button>
            </div>
        </div>
</div>

<div class="container credential-headers-align first_credential">

    <mat-expansion-panel  [expanded]="true" >
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="credential">
                    <h2>GS1 Party Credential</h2>
                </div>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
             <div class="container">
                <div class="row">
                  <div class="col-2">
                    <img src="../assets/gs1_logo.jpg"/>
                </div>
                <div class="col-2">
                    <div *ngIf="credentialOutput.showStatus" style="display: flex; justify-content: center; align-items: center; height: 160px;">
                        <img src="../assets/success.png" width="60" height="60" *ngIf="credentialOutput.validated"/>
                        <img src="../assets/fail.png" width="60" height="60"  *ngIf="!credentialOutput.validated"/>
                    </div>
                </div>
                <div class="col-8">
                    <div class="container">
                        <div class="row">
                            <div class="col-4">
                                Issuer:
                            </div>
                            <div class="col-8">
                                {{credentialOutput.issuer}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                Issued Date:
                            </div>
                            <div class="col-8">
                                {{credentialOutput.issuedDate }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                Root Of Trust DID:
                            </div>
                            <div class="col-8">
                                {{credentialOutput.rootOfTrustDid}}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                Root Of Trust Organization:
                            </div>
                            <div class="col-8">
                                {{credentialOutput.rootOfTrustOrganization}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                Organization Name:
                            </div>
                            <div class="col-8">
                                {{credentialOutput.organizationName}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                Party GLN:
                            </div>
                            <div class="col-8">
                                {{credentialOutput.partyGln}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                Errors:
                            </div>
                            <div class="col-8">
                                <ul class="no-bullets">
                                    <li *ngFor="let error of credentialOutput.errors">
                                      {{ error }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>



<!--   

    <div>
        <div>
            <ul>
                <li>Issuer is DID representing GS1 Global Office​</li>
                <li>Credential subject is DID representing GS1 Member Organisation</li>
            </ul>
        </div>
        <div class="credential_subject">
            <h3>Credential Subject</h3>

            <table mat-table [dataSource]="licenseCredential.fields" class="mat-elevation-z8">
              

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                      
                <ng-container matColumnDef="value">
                  <th mat-header-cell *matHeaderCellDef> Value </th>
                  <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        </div>
        <div class="credential_subject">
            <h3>Errors</h3>
            <span *ngIf="licenseCredential.errors.length == 0">
                No Errors
            </span>
            <ul class="no-bullets">
                <li *ngFor="let error of licenseCredential.errors" class="errors">
                    {{error.message}}
                </li>
            </ul>
        </div>
    </div>     -->